import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Detailsbreadcrumb from '../othercomponents/Detailsbreadcrumb';
import { Helmet } from 'react-helmet';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loader from '../assets/Blocks-1.2s-381px.svg';
import dummy from '../assets/dummy.jpeg';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const options = {
  showStatus: false,
  infiniteLoop: true,
  onSwipeMove: true,
  showArrows: true,
  useKeyboardArrows: true,
};

export class Productdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      p_items: [],
      related_items: [],
      type: '',
      modal: 'all',
      name: '',
      image: [],
      discription: '',
      product_link: this.props.link,
      isLoading: true,
      picture: [],
      sender_name: '',
      email: '',
      phone: '',
      message: '',
      quantity: '',
      product_id: '',
      sku: '',
      open: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchProductDetails();
  }

  fetchProductDetails = () => {
    fetch(global.api + 'get-product-details-web', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product_link: this.state.product_link,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ p_items: json.data });
          json.data.map((val) => {
            this.setState({
              name: val.name,
              discription: val.discription,
              type: val.type,
              picture: val.picture,
              product_id: val.id,
              sku: val.sku,
            });
          });
          this.fetchRelatedProducts(this.state.type, this.state.modal);
        } else {
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  fetchRelatedProducts = (type, all) => {
    fetch(global.api + 'get-product-list-web', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category_link: type,
        subcategory_link: all,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ related_items: json.data.data });
        return json;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  send_enquiry = () => {
    if (
      this.state.sender_name === '' ||
      this.state.quantity === '' ||
      this.state.enquiry === '' ||
      this.state.product_id === '' ||
      this.state.product_name === ''
    ) {
      toast.warn('Pleasse fill the mandatory fields.', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'dark',
      });
    } else {
      fetch(global.api + 'enquiry-form', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: this.state.sender_name,
          mobile: this.state.phone,
          email: this.state.email,
          quantity: this.state.quantity,
          message: this.state.message,
          product_id: this.state.product_id,
          product_name: this.state.name,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status) {
            toast.success(json.message, {
              position: 'bottom-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'dark',
            });
            this.setState({
              sender_name: '',
              phone: '',
              email: '',
              quantity: '',
              message: '',
            });
            this.onCloseModal();
          } else {
            toast.error(json.message, {
              position: 'bottom-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'dark',
            });
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    let rel_data = this.state.related_items.slice(0, 5).map((value) => {
      return (
        <div className="col-md-2">
          <Link to={'/productdetails/' + value.product_link} target="_blank">
            {value.picture.length > 0 ? (
              <img
                src={global.img + value.picture[0].src}
                title={value.name}
                alt=""
                className="img-responsive reg-image"
              />
            ) : (
              <img src={dummy} alt="" style={{ width: '100%' }} />
            )}
            <h4 style={{ textAlign: 'center' }}>{value.name}</h4>
          </Link>
        </div>
      );
    });
    return (
      <div>
        <Helmet>
          <title>Calyron - {this.state.name}</title>
        </Helmet>
        {this.state.isLoading ? (
          <div
            className="container"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <img src={loader} alt="" />
          </div>
        ) : (
          <>
            <div id="product-product" className="container">
              <Detailsbreadcrumb />
              <div className="row">
                <div id="content" className="col-sm-12 productpage">
                  <div className="row">
                    <div className="col-sm-5 product-left">
                      <div className="product-info">
                        <div className="left product-image thumbnails">
                          {/* <!-- CapricaThemes Cloud-Zoom Image Effect Start --> */}
                          {this.state.picture.length > 1 ? (
                            <Carousel {...options}>
                              {this.state.picture.map((val) => {
                                return (
                                  <img
                                    src={global.img + val.src}
                                    alt=""
                                    className="img-responsive reg-image"
                                  />
                                );
                              })}
                            </Carousel>
                          ) : (
                            <img
                              src={global.img + this.state.picture.src}
                              alt=""
                              className="img-responsive reg-image"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7 product-right">
                      <h3 className="product-title">{this.state.name}</h3>

                      <ul className="list-unstyled price">
                        <li style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <h4
                              className="product-price"
                              style={{
                                marginBottom: '0px',
                                fontSize: '18px',
                                fontWeight: 'bold',
                              }}
                            >
                              Description:
                            </h4>
                          </div>
                        </li>
                        <li>
                          <h6
                            style={{ fontSize: '14px' }}
                            dangerouslySetInnerHTML={{
                              __html: this.state.discription,
                            }}
                          ></h6>
                        </li>
                        <li style={{ width: '100%' }}>
                          <h4
                            className="product-price"
                            style={{
                              marginBottom: '0px',
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}
                          >
                            Product Specification:
                          </h4>
                        </li>
                        <li className="price-tax">
                          <h4
                            className="product-price"
                            style={{ marginBottom: '0px' }}
                          >
                            <b>SKU :</b> {this.state.sku}
                          </h4>
                        </li>
                      </ul>

                      <div
                        enctype="multipart/form-data"
                        className="form-horizontal"
                      >
                        <div className="buttons">
                          <div className="pull-left">
                            <input
                              className="btn btn-primary"
                              type="submit"
                              value="Send Enquiry!"
                              onClick={() => this.onOpenModal()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- product page tab code start--> */}
                  </div>

                  {/* <!-- CapricaThemes Related Products Start -->	 */}
                  <div className="box related">
                    <div className="box-head">
                      <div className="box-heading">Related Products</div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 related_product_head">
                        <h4>Product Related to : {this.state.type}</h4>
                      </div>
                      <div
                        className="col-md-6 related_product_head"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Link to={'/categories/all/' + this.state.type}>
                          <input
                            className="btn btn-primary"
                            type="submit"
                            value="See all products"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="box-content">
                      <div id="products-related" className="related-products">
                        <div className="row">{rel_data}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              open={this.state.open}
              onClose={() => this.onCloseModal()}
              center
              classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
              }}
              animationDuration={800}
            >
              <div style={{ padding: '20px' }}>
                <fieldset>
                  <h3 className="m-0 mb-2">
                    Enquiry Form
                    <br />
                    <small className="text-muted text-danger">
                      (Fields marked with * are required)
                    </small>
                  </h3>
                  <div className="form-group ">
                    <div className="col-md-6 required">
                      <div className="row">
                        <label
                          className="col-sm-12 control-label"
                          for="input-name"
                        >
                          Your Name
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            name="name"
                            id="input-name"
                            className="form-control"
                            value={this.state.sender_name}
                            onChange={(e) =>
                              this.setState({
                                sender_name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="row">
                        <label
                          className="col-sm-12 control-label"
                          for="input-email"
                        >
                          Your Email
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="email"
                            name="email"
                            id="input-email"
                            className="form-control"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group ">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          className="col-sm-12 control-label"
                          for="input-number"
                        >
                          Your Phone Number
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            maxLength={10}
                            name="number"
                            id="input-number"
                            className="form-control"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 required">
                      <div className="row">
                        <label
                          className="col-sm-12 control-label"
                          for="input-number"
                        >
                          Quantity
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="number"
                            name="number"
                            id="input-number"
                            className="form-control"
                            value={this.state.quantity}
                            onChange={(e) =>
                              this.setState({
                                quantity: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group required">
                    <label
                      className="col-sm-12 control-label"
                      for="input-enquiry"
                    >
                      Enquiry
                    </label>
                    <div className="col-sm-12">
                      <textarea
                        name="enquiry"
                        rows="4"
                        id="input-enquiry"
                        className="form-control"
                        value={this.state.message}
                        onChange={(e) =>
                          this.setState({ message: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div
                    enctype="multipart/form-data"
                    className="form-horizontal"
                  >
                    <div className="buttons">
                      <div className="pull-right">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value="Send Enquiry!"
                          onClick={() => this.send_enquiry()}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </Modal>
          </>
        )}

        <ToastContainer />
      </div>
    );
  }
}

export default (props) => <Productdetails {...useParams()} {...props} />;
