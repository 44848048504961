import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useParams } from "react-router-dom";
import dummy from "../assets/dummy.jpeg";

const options = {
  pagination: false,
  dotsContainer: false,
  showStatus: false,
  margin: 20,
  responsiveClass: true,
  autoplay: true,
  smartSpeed: 500,
  loop: true,
  controlsClass: false,
  dotsEach: false,
  dots: true,
  nav: true,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
};

export class Featuredproducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      category_id: "",
      tabdata: [],
    };
  }
  componentDidMount() {
    this.fetchdata();
  }

  fetch_tabdata = (id) => {
    fetch(global.api + "fetch-featured-category-product?category_id=" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ tabdata: json.data });
        } else {
        }
        return json;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  fetchdata = () => {
    fetch(global.api + "fetch-home-data-web", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ items: json.data });
        this.setState({ category_id: json.data[0].id });
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.fetch_tabdata(this.state.category_id);
        this.setState({ isLoading: false });
      });
  };

  render() {
    let data = this.state.items.map((val) => {
      return (
        <li className="tab">
          <a
            data-toggle="tab"
            onClick={() => this.fetch_tabdata(val.id)}
            style={{ cursor: "pointer" }}
          >
            {val.tab_name}
          </a>
        </li>
      );
    });
    return (
      <>
        <div className="hometab box">
          <div className="container">
            <div className="tab-head">
              <div className="hometab-heading box-heading">
                Featured Products
              </div>
              <div id="categorytabs" className="htabs">
                <ul className="etabs">{data}</ul>
              </div>
            </div>
            <div id="categorytab-20" className="tab-content">
              <div className="box">
                <OwlCarousel {...options}>
                  {this.state.tabdata.map((key) => {
                    return (
                      <div className="slider-item">
                        <div className="product-block product-thumb">
                          <div className="product-block-inner">
                            <div className="image">
                              <Link to={"/productdetails/" + key.product_link}>
                                {key.picture.length > 0 ? (
                                  <img
                                    src={global.img + key.picture[0].src}
                                    title={key.name}
                                    alt={key.name}
                                    className="img-responsive"
                                  />
                                ) : (
                                  <img
                                    src={dummy}
                                    title="Armour Suit"
                                    alt=""
                                    className="img-responsive"
                                  />
                                )}
                              </Link>
                            </div>
                            <div className="product-details">
                              <div
                                className="caption"
                                style={{ textAlign: "center" }}
                              >
                                <Link
                                  to={"/productdetails/" + key.product_link}
                                >
                                  <h4>{key.name}</h4>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default (props) => <Featuredproducts {...useParams()} {...props} />;
