import React, { Component } from "react";
import Mainslider from "../components/Mainslider";
import Topcategories from "../components/Topcategories";
import Featuredproducts from "../components/Featuredproducts";
import Testimonials from "../components/Testimonials";
import Mainbanner from "../components/Mainbanner";
import Helmet from "react-helmet";
import loader from "../assets/Blocks-1.2s-381px.svg";
export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchCatdata();
  }

  fetchCatdata = () => {
    fetch(global.api + "fetch-home-data-web", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ items: json.slider });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <img src={loader} />
          </div>
        ) : (
          <>
            <Helmet>
              <title>Calyron - Home Page</title>
            </Helmet>
            {this.state.items.length > 0 ? (
              <Mainslider slider={this.state.items} />
            ) : (
              <></>
            )}
            <Topcategories />
            <Featuredproducts />
            <Mainbanner />
            <Testimonials />
          </>
        )}
      </>
    );
  }
}

export default Home;
