import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const options = {
  showThumbs: false,
  showStatus: false,
  infiniteLoop: true,
  onSwipeMove: true,
  autoPlay: true,
};

export class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    this.testimonials();
  }

  testimonials = () => {
    fetch(global.api + "fetch-home-data-web", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ data: json.testomonial });
        } else {
        }
        return json;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  render() {
    return (
      <>
        <div id="ctqqcategorylistblock" className="ctcategory_listblock" style={{marginBottom:"0px"}}>
          <div className="container">
            <div className="box-heading">Testimonials</div>
            <div className="row home_row">
              <div id="content" className="col-sm-12">
                <div id="cttestimonialcmsblock">
                  <div className="testimonial_container container">
                    <div className="testimonial_wrapper">
                      <div className="testimonial-area">
                        <ul style={{padding:"0"}}>
                          <Carousel {...options}>
                            {this.state.data.map((value) => {
                              return (
                                <li className="slider-item">
                                  <div className="testimonial-item">
                                    <div className="item cms_face">
                                      <div className="quote-image"></div>
                                      <div className="product_inner_cms">
                                        <div className="title">
                                          <div className="name">
                                           <strong> {value.name}</strong>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="des">
                                      {value.description}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </Carousel>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonials;
