import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import dummy from "../assets/dummy.jpg";

const options = {
  showThumbs: false,
  showStatus: false,
  infiniteLoop: true,
  onSwipeMove: true,
  autoPlay: true,
};

export class Mainslider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  render() {
    let data = this.props.slider.map((val) => {
      return <img src={global.img + val.file} alt="slider" />;
    });
    return (
      <>
        <Carousel {...options}>{data}</Carousel>
      </>
    );
  }
}

export default Mainslider;
