import React, { Component } from 'react';
import Contactbreadcrumb from '../othercomponents/Contactbreadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Helmet from 'react-helmet';
export class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      phone: '',
      email: '',
      subject: '',
      message: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  SendContactusdata = () => {
    if (
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.subject == '' ||
      this.state.message === ''
    ) {
      toast.warn('All data fields required!', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'dark',
      });
    } else {
      fetch(global.api + 'contact_us', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          subject: this.state.subject,
          message: this.state.message,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status) {
            toast.success(json.message, {
              position: 'bottom-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'dark',
            });
            this.setState({
              name: '',
              phone: '',
              email: '',
              subject: '',
              message: '',
            });
          } else {
            toast.error(json.message, {
              position: 'bottom-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'dark',
            });
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Calyron - Contact Us</title>
        </Helmet>
        <div id="information-contact" className="container">
          <Contactbreadcrumb />
          <div className="row">
            <div id="content" className="col-sm-12">
              <div className="form-horizontal">
                <fieldset>
                  <h3 className="m-0 mb-2">
                    Contact Form
                    <br />
                    <small className="text-muted text-danger">
                      (Fields marked with * are required)
                    </small>
                  </h3>
                  <div className="form-group required ">
                    <label className="col-sm-2 control-label" for="input-name">
                      Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name="name"
                        id="input-name"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        value={this.state.name}
                      />
                    </div>
                  </div>
                  <div className="form-group ">
                    <label className="col-sm-2 control-label" for="input-name">
                      Phone
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name="name"
                        id="input-name"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        value={this.state.phone}
                        maxLength={10}
                      />
                    </div>
                  </div>
                  <div className="form-group required">
                    <label className="col-sm-2 control-label" for="input-email">
                      E-Mail Address
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name="email"
                        id="input-email"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="form-group required">
                    <label className="col-sm-2 control-label" for="input-name">
                      Subject
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name="name"
                        id="input-name"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ subject: e.target.value })
                        }
                        value={this.state.subject}
                      />
                    </div>
                  </div>
                  <div className="form-group required">
                    <label
                      className="col-sm-2 control-label"
                      for="input-enquiry"
                    >
                      Message
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        name="enquiry"
                        rows="4"
                        id="input-enquiry"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ message: e.target.value })
                        }
                        value={this.state.message}
                      ></textarea>
                    </div>
                  </div>
                </fieldset>
                <div
                  className="buttons col-sm-7"
                  onClick={() => this.SendContactusdata()}
                >
                  <div className="pull-right">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}

export default Contact;
