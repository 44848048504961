import React, { Component } from "react";
import { useParams } from "react-router-dom";
import Searchbreadcrumb from "../othercomponents/Searchbreadcrumb";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../assets/Blocks-1.2s-381px.svg";
import no_prodtc from "../assets/notfound.jpg";
import Helmet from "react-helmet";
import dummy from "../assets/dummy.jpeg";

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: this.props.params.search,
      data: [],
      isLoading: true,
    };
  }
  componentDidMount() {
    this.search(this.props.params.search);
  }

  componentWillReceiveProps(nextProps) {
    var nextProductId = nextProps.params.search;
    this.search(nextProductId);
  }

  search = (e) => {
    fetch(global.api + "search-product", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search: e,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ data: json.data, visible: true });
        } else {
          this.setState({ data: [], visible: false });
        }
        return json;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false, nosearch: true });
        if (this.state.search == "") {
          this.setState({ visible: false });
        }
      });
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Calyron - Search - {this.props.params.search}</title>
        </Helmet>
        {this.state.isLoading ? (
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <img src={loader} />
          </div>
        ) : (
          <div id="product-category" className="container">
            <Searchbreadcrumb />
            <div className="row">
              <div className="col-md-12" id="content">
                <div className="row cat_prod" style={{ marginBottom: "10px" }}>
                  {this.state.data.length != 0 ? (
                    <>
                      {this.state.data.map((item) => {
                        return (
                          <div className="col-lg-3 shop_column">
                            <div className="product-block product-thumb">
                              <div className="product-block-inner">
                                <div className="image">
                                  {item.picture.length > 0 ? (
                                    <Link
                                      to={
                                        "/productdetails/" + item.product_link
                                      }
                                    >
                                      <img
                                        src={global.img + item.picture[0].src}
                                        title={item.name}
                                        alt={item.name}
                                        className="img-responsive reg-image"
                                        style={{ height: "250px" }}
                                      />
                                    </Link>
                                  ) : (
                                    <img src={dummy} />
                                  )}
                                </div>
                                <div className="product-details">
                                  <div className="caption">
                                    <h4>
                                      <Link
                                        to={
                                          "/productdetails/" + item.product_link
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img src={no_prodtc} alt="" style={{ width: "40%" }} />
                      <h4>No Products Found!</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </>
    );
  }
}

export default (props) => <Search {...props} params={useParams()} />;
