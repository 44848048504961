import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const options = {
  showThumbs: false,
  showStatus: false,
  infiniteLoop: true,
  onSwipeMove: true,
  autoPlay: true,
};

export class Mainbanner extends Component {
  constructor() {
    super();
    this.state = {
      banner: [],
    };
  }
  componentDidMount() {
    this.bannerData();
  }
  bannerData = () => {
    fetch(global.api + "fetch-home-data-web", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ banner: json.footer });
        console.log(this.state.banner);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  };
  render() {
    let data = this.state.banner.map((value) => {
      return (
        <img
          src={global.img + value.path}
          alt="cms-banner"
          className="banner-image1"
          style={{ width: "100%", height: "500px" }}
        />
      );
    });

    return (
      <div id="ctbannercmsblock" className="block ctbanners">
        <div className="ctbanner_container">
          <div className="cmsbanners">
            <div className="cmsbanner-inner">
              <div className="cmsbanner cmsbanner1">
                <a className="banner-anchor">
                  <Carousel {...options}>{data}</Carousel>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Mainbanner;
