import React, { Component } from "react";
import { useParams } from "react-router-dom";
import Categoriesbreadcrumb from "../othercomponents/Categoriesbreadcrumb";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../assets/Blocks-1.2s-381px.svg";
import no_prodtc from "../assets/notfound.jpg";
import Helmet from "react-helmet";
import dummy from "../assets/dummy.jpeg";
import { Accordion } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';

export class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      product_cat: [],
      category_data: [],
      link: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadProductList(
      this.props.params.subcat_link,
      this.props.params.cat_link
    );
    this.get_category();
  }

  componentWillReceiveProps(nextProps) {
    this.loadProductList(
      nextProps.params.subcat_link,
      nextProps.params.cat_link
    );
  }

  get_category() {
    fetch(global.api + "get-jwellery-category-web", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ category_data: json.data });
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }



  loadProductList = (subcat_link, metal_link) => {
    this.setState({ isLoading: true });
    fetch(global.api + "get-product-list-web", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subcategory_link: subcat_link,
        category_link: metal_link,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ product_cat: json.data.data });
        } else {
          this.setState({ product_cat: [] });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  render() {
    let sidebar = this.state.category_data.map((item) => {
      return (
        <ul>
          <li
            style={{
              color: "black",
              fontWeight: "bold",
              textDecoration: "underline",
              fontSize: "15px",
              padding: "0",
            }}
          >
            {item.parent == "0" ? (
              <>
                {item.name}
                {/* <i
                  class="fa-solid fa-arrow-turn-down"
                  style={{ fontSize: "10px", marginLeft: "10px" }}
                ></i> */}
              </>
            ) : (
              <></>
            )}
          </li>
          <ul>
            {item.children_categories.map((values) => {
              return (
                <Link to={"/categories/all/" + values.link}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <li style={{ padding: "3px" }}>
                      <i
                        class="fa-solid fa-arrow-right"
                        style={{ fontSize: "10px", marginRight: "10px" }}
                      ></i>
                      {values.name}
                    </li>
                    <p style={{ margin: "0" }}>({values.product_count})</p>
                  </div>
                </Link>
              );
            })}
          </ul>
        </ul>
      );
    });
    return (
      <>
        <Helmet>
          <title>Calyron - {this.props.params.cat_link}</title>
        </Helmet>
        {this.state.isLoading ? (
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <img src={loader} />
          </div>
        ) : (
          <div id="product-category" className="container">
            <Categoriesbreadcrumb />
            <div className="row">
              <aside id="column-left" className="col-md-3">
                <div className="box">
                  <div
                    className="box-heading"
                    style={{ fontSize: "25px", textDecoration: "underline" }}
                  >
                    Categories
                  </div>
                  <div className="box-content" style={{ display: "block" }}>
                    <ul className="box-category treeview-list treeview">
                      {sidebar}
                    </ul>
                  </div>
                </div>
              </aside>
              <div className="col-md-9" id="content">
                <div className="row cat_prod" style={{ marginBottom: "10px" }}>
                  {this.state.product_cat.length != 0 ? (
                    <>
                      {this.state.product_cat.map((item) => {
                        return (
                          <div className="col-lg-4 shop_column">
                            <div className="product-block product-thumb">
                              <div className="product-block-inner">
                                <div className="image">
                                  {item.picture.length > 0 ? (
                                    <Link
                                      to={
                                        "/productdetails/" + item.product_link
                                      }
                                    >
                                      <img
                                        src={global.img + item.picture[0].src}
                                        title={item.name}
                                        alt={item.name}
                                        className="img-responsive reg-image"
                                        style={{ height: "250px" }}
                                      />
                                    </Link>
                                  ) : (
                                    <img src={dummy} />
                                  )}
                                </div>
                                <div className="product-details">
                                  <div className="caption">
                                    <h4>
                                      <Link
                                        to={
                                          "/productdetails/" + item.product_link
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img src={no_prodtc} alt="" style={{ width: "40%" }} />
                      <h4>No Products Found!</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </>
    );
  }
}

export default (props) => <Categories {...props} params={useParams()} />;
