import { Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import Footer from "./othercomponents/Footer";
import Contact from "./pages/Contact";
import Header from "./othercomponents/Header";
import Categories from "./pages/Categories";
import Home from "./pages/Home";
import Productdetails from "./pages/Productdetails";
import { Component } from "react";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import About from "./pages/Aboutus";
import Search from "./pages/Search";

global.api = "https://demo.webixun.com/CalyronAPI/public/api/";
global.img = "https://calyron.com/CDN/";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/categories/:subcat_link/:cat_link"
            render={(props) => <Categories {...props} />}
            element={<Categories />}
          />
          <Route path="/productdetails/:link" element={<Productdetails />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/about" element={<About />} />
          <Route path="/search/:search" element={<Search/>} />
        </Routes>
        <Footer style={{ bottom: "0" }} />
      </>
    );
  }
}

export default (props) => <App {...useParams()} {...props} />;
