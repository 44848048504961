import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Categoriesbreadcrumb extends Component {
  render() {
    return (
      <div className="wrap-breadcrumb parallax-breadcrumb" >
        <div className="container"></div>

        <div>
          <ul className="breadcrumb ">
            <li>
              <Link to="/">
                <i className="fa fa-home"></i>
              </Link>
            </li>
            <li>
              <Link to="/contactus">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Categoriesbreadcrumb;
