import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/footer_logo.png";

const year = new Date().getFullYear();

export class Footer extends Component {
  constructor(props) {
    super(props);
    {
      this.state = {
        social_links: [],
        contact: [],
        email: "",
        phone: "",
      };
    }
  }
  componentDidMount() {
    this.footerData();
  }

  footerData = () => {
    fetch(global.api + "dynamic-pages", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({
            social_links: json.social_link,
            contact: json.contact,
          });
          this.state.contact.map((val) => {
            return this.setState({ email: val.email, phone: val.contact });
          });
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  render() {
    return (
      <footer>
        <div id="footer" className="container">
          <div className="row">
            <div className="footer-blocks">
              <div className="col-sm-3 column footerleft">
                <div id="ctfootercmsblock" className="footer-cms-block">
                  <div id="footerlogo">
                    <div className="footerdiv">
                      <Link to="/">
                        <div
                          className="footerlogo"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={logo}
                            style={{ height: "85px" }}
                            alt="logo"
                          />
                        </div>
                      </Link>
                      <div
                        className="footerdesc"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="social-block">
                          <ul>
                            {this.state.social_links.map((val) => {
                              return (
                                <a href={val.link} target="_blank">
                                  <li
                                    className="facebook"
                                    style={{ fontSize: "20px" }}
                                  >
                                    <i className={val.icon}></i>
                                  </li>
                                </a>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="info" className="col-sm-3 column">
                <h5>Information</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div id="extra-link" className="col-sm-3 column">
                <h5>Extras</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/contactus">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 column footerright">
                <div className="contact-block">
                  <h5>Store information</h5>
                  <ul>
                    <li>
                      <i className="fa fa-phone"></i>
                      <a href={"tel:" + this.state.phone}>
                        <span>+91 {this.state.phone}</span>
                      </a>
                    </li>

                    <li>
                      <i className="fa fa-envelope"></i>
                      <a href={"mailto:" + this.state.email}>
                        <span>{this.state.email}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="bottom-footer" className="bottomfooter">
          <div className="container">
            <div className="footer-inner">
              <div className="row">
                <div className="col-md-12" style={{display:"flex",justifyContent:"center"}}>
                  <p id="powered" className="powered">
                    Made with love By{" "}
                    <a href="https://webixun.com" target="_blank">
                      Webixun Infoways Pvt. Ltd.
                    </a>
                    &copy; {year}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
