import React, { Component } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import dummy from "../assets/dummy.jpeg";

const options = {
  showStatus: false,
  margin: 20,
  responsiveClass: true,
  dots: true,
  autoplay: false,
  smartSpeed: 500,
  loop: true,
  nav:true,
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};
export class Topcategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoading: true,
    };
  }
  componentDidMount() {
    this.fetchdata();
  }

  fetchdata = () => {
    fetch(global.api + "fetch-home-data-web", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ items: json.featured });
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div id="ctqqcategorylistblock" className="ctcategory_listblock">
        <div className="container">
          <div className="box-heading">Top Categories</div>
          <div className="ctcategory_block">
            <div className="category_lists">
              <div className="row">
                {this.state.isLoading ? (
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <img
                        src={dummy}
                        alt=""
                        style={{ width: "100%", height: "300px" }}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <img
                        src={dummy}
                        alt=""
                        style={{ width: "100%", height: "300px" }}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <img
                        src={dummy}
                        alt=""
                        style={{ width: "100%", height: "300px" }}
                      />
                    </div>
                  </div>
                ) : (
                  <OwlCarousel {...options}>
                    {this.state.items.map((value) => {
                      return (
                          <div className="cate_image">
                            <div className="cate-inner">
                              <Link
                                to={"/categories/all/" + value.category_link}
                              >
                                <img
                                  src={global.img + value.image}
                                  className="img-responsive top-category-image"
                                  alt={value.image}
                                  style={{ width: "100%", height: "auto" }}
                                />

                                <h3>{value.category_link}</h3>
                              </Link>
                            </div>
                          </div>
                      );
                    })}
                  </OwlCarousel>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Topcategories;
