import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";

export class Header extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      child: [],
      childData: [],
      data: [],
      search: "",
    };
  }

  componentDidMount() {
    this.fetchHeaderData();
  }

  fetchHeaderData = () => {
    fetch(global.api + "get-header", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.setState({ items: json.data });
        this.state.items.map((key) => {
          {
            key.child.length > 0
              ? this.setState({ childData: key.child })
              : this.setState({ childData: [] });
          }
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  mobileMenuClose = () => {
    document.getElementById("mobile_menu").style.display = "none";
  };

  render() {
    return (
      <>
        <header>
          <div className="header-container">
            <div className="header-main header-main_new">
              <div className="container">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="header-logo">
                    <div id="logo">
                      <Link to="/">
                        <h1 style={{ marginBottom: "0", fontSize: "30px" }}>
                          Calyron
                        </h1>
                      </Link>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="search_box_header"
                    placeholder="Search here!"
                    value={this.state.search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    onKeyPress={(e) => {
                      this.state.search == "" ? (
                        <></>
                      ) : (
                        e.key === "Enter" &&
                        this.props.navigate("/Search/" + e.target.value)
                      );
                    }}
                  />
                  {this.state.search == "" ? (
                    <div className="search_icon_header">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                  ) : (
                    <div
                      className="search_icon_header"
                      onClick={() => {
                        this.props.navigate("/Search/" + this.state.search);
                      }}
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
        <header>
          <div className="header-container">
            <div className="header-main">
              <div className="container">
                <div className="row">
                  <nav className="nav-container" role="navigation">
                    <div className="nav-inner">
                      {/* <!-- ======= Menu Code START ========= --> */}
                      {/* <!-- Opencart 3 level Category Menu--> */}
                      <div id="menu" className="main-menu">
                        <ul className="nav navbar-nav">
                          {this.state.items.map((main_cat) => {
                            return (
                              <li className="top_level dropdown">
                                <a
                                  style={{ cursor: "pointer" }}
                                >
                                  {main_cat.name}
                                </a>
                                {main_cat.child.length > 0 ? (
                                  <div className="dropdown-menu">
                                    <div className="dropdown-inner">
                                      <ul className="list-unstyled childs_2">
                                        {main_cat.child.map((sub_cat) => {
                                          return (
                                            <li>
                                              <Link
                                                to={
                                                  "/categories/" +
                                                  "all" +
                                                  "/" +
                                                  sub_cat.link
                                                }
                                              >
                                                {sub_cat.name}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    {/* <!--  =============================================== Mobile menu start  =============================================  --> */}
                    <div id="res-menu" className="main-menu nav-container1">
                      <div className="nav-responsive">
                        <span>Menu</span>
                        <div className="expandable"></div>
                      </div>
                      <ul className="main-navigation" id="mobile_menu">
                        {this.state.items.map((main_cat) => {
                          return (
                            <li className="top_level dropdown">
                              <Link
                                to={"/categories/all/" + main_cat.link}
                                onClick={() => {
                                  this.mobileMenuClose();
                                }}
                              >
                                {main_cat.name}
                              </Link>
                              {main_cat.child.length > 0 ? (
                                <div className="dropdown-menu">
                                  <div className="dropdown-inner">
                                    <ul className="list-unstyled childs_2">
                                      {main_cat.child.map((sub_cat) => {
                                        return (
                                          <li>
                                            <Link
                                              to={
                                                "/categories/" +
                                                "all" +
                                                "/" +
                                                sub_cat.link
                                              }
                                              onClick={() => {
                                                this.mobileMenuClose();
                                              }}
                                            >
                                              {sub_cat.name}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

function Navigate(props) {
  const abcd = useNavigate();
  return <Header {...props} navigate={abcd} />;
}

export default (props) => <Navigate {...props} />;
