import React, { Component } from "react";
import Termsbreadcrumb from "../othercomponents/Termsbreadcrumb";
import loader from "../assets/Blocks-1.2s-381px.svg";
import Helmet from "react-helmet";
export class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetch_data();
  }

  fetch_data = () => {
    fetch(global.api + "dynamic-pages", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ data: json.dynamic_page[3] });
        } else {
        }
        return json;
      })
      .catch((error) => console.error(error))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };
  render() {
    return (
      <>
      <Helmet>
          <title>Calyron - Terms & Conditions</title>
      </Helmet>
        <Termsbreadcrumb />
        {this.state.isLoading ? (
          <div className="container">
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <img src={loader} />
            </div>
          </div>
        ) : (
          <div id="ctqqcategorylistblock" className="ctcategory_listblock">
            <div className="container">
              <div className="box-heading" text="center">
                {this.state.data.page_title}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.data.page_data,
                }}
              ></p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Terms;
